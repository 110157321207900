.inputtype input[type='number']::-webkit-inner-spin-button,
.inputtype input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.headerButton {
  color: #cc0000;
  background-color: white;
}

.checkboxesStyle {
  height: 120px;
  overflow: auto;
}

.status-card {
  background-color: #fff; /* Set the background color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Add a transition for interactivity */
}

.status-card:hover {
  transform: scale(1.05); /* Scale the card on hover */
}

.status-title {
  color: #333; /* Set the color for the title */
}
